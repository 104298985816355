///HEADER SCRIPT START::::::::::::::::::::::::::::::::::::::::::
$(document).ready(function(){
    var headerJs = this;
    headerJs.el;
    headerJs.breakpoint = 768;
    headerJs.upperH;
    headerJs.menuH;
    headerJs.menuW;
    headerJs.winW;
    headerJs.winH;
    headerJs.menuPad;
    headerJs.viewmode;
    // INIT ---------------------------------------
    headerJs.init = function(){
        headerJs.sizes();
        headerJs.stateReset();
        headerJs.loadAdjust(); //anti flickker
        //headerJs.viewmodeSet();
        // headerJs.bodyDisplace(); //FOR FIXED MENUS
        headerJs.slidePosition(); //set the position of the slides
        headerJs.mobileMenu();
        headerJs.accordion();
    }
    //HELPER FUNCTIONS---------------------------
    //check is is PC
    headerJs.isPc = function(){
        if(headerJs.winW > headerJs.breakpoint){
            return true;
        }else{
            return false;
        }
    }

    // MAIN FUNCTIONS ----------------------------
    // get and set all sizes on load and resize
    headerJs.sizes = function(){
        var mod = this;
        mod.menu = $('.l_header_menu');
        mod.init = function(){
            mod.getH();
            mod.getW();
            mod.menuWi();
            // mod.menuHe();
            mod.menuSlide();
        }
        //get heights
        mod.getH =function(){
            headerJs.upperH = $('.l_header_upper').innerHeight();
            headerJs.menuH = mod.menu.innerHeight();
            headerJs.winH = $(window).height();
        }
        //get width
        mod.getW =function(){
            headerJs.winW = $(window).width();
        }
        //get menu width
        mod.menuWi =function(){
            if(headerJs.isPc()){
                headerJs.menuW =  mod.menu.innerWidth();
            }
        }
        //set menu max-height (for mobile)
        // mod.menuHe = function(){
        //     var maxH = headerJs.winH - headerJs.upperH;
        //     var mvH = $('.mv').height();
        //     var theMax = headerJs.isPc() ? '' : maxH;
        //     $('.l_header_menu').css({'max-height':theMax});
        // }
        //set slide data on PC
        mod.menuSlide =function(){
            var $theMenu = $('.l_header_menu');
            var $theSlides = $('.l_header_menu_slide');
            if(headerJs.isPc()){
                $theSlides.each(function(){
                     //select title
                     var title = $(this).prev('.l_header_menu_title');
                     var titleTop = title.offset().top;
                     //add the data
                    $(this).data({
                        top: titleTop
                    });

                });
            }
        }
        //run mod
        mod.init();
        $(window).on('resize',mod.init);
    }
    //set the state of slide on window resize, this will reset menu tu start state on each viewport
    headerJs.stateReset = function(){
        var mod = this;
        mod.targets =[$('.l_header_menu_slide')];
        
        mod.init = function(){
            mod.runner();
            
        }
        //reset functions
        mod.slideReset = function(value){
            var $slide =$('.l_header_menu_slide');
            if(value){ //is PC, value set after all reset functions
                $slide.show();
            }else{
                $slide.hide();
            }
    
        }

        
        /// this makes the function run
        mod.runner = function(){
            
            $(mod.targets).each(function(){
                var mobileClass = 'is_mobile';
                var isSet = $(this).hasClass(mobileClass);
                
                // Initial value PC, WILL NOT RUN AT LOAD on pc size
                //does the same for PC size
                if(headerJs.isPc() && isSet){ 
                    mod.slideReset(isSet);
                    //finally remove the class
                    $(this).removeClass(mobileClass);
                }
                // checks is is_mobile class is available, if not it performs the tasks.
                else if(!headerJs.isPc() && !isSet){ //state changes to mobile
                    
                    mod.slideReset(isSet);
                    //finally add the class
                    $(this).addClass(mobileClass);
                };
            })
        }
        // this initializes it
        mod.init();
        $(window).resize(mod.init);
    }
    //position the slide
    headerJs.slidePosition = function(){
        var mod = this;
        mod.theSlide = $('.l_header_menu_slide');
        mod.init = function(){
            mod.positionEach()
        }
        mod.slideAdjust = function(target){
            var triggerBtn = $(target).prev();
            var theW = mod.targetWi(target);
            var targetW = $(target).innerWidth();
            var btnW = triggerBtn.innerWidth();
            var triggerOffset = $(target).prev().offset().left;
            var thisContainer = $(target).parent();
            var containerOffset = thisContainer.offset().left;

            //add the offset of the trigger plus half the size of trigger, minus the half of target width
            var centering = ((btnW/2)+triggerOffset) - targetW/2;
            //now remove container offset and padding
            //centering = centering - containerOffset;
            //reset if less than zero
            centering = centering < 0 ? 0 : centering;
            //reset if too far, make offset window width minus target width
            centering = headerJs.winW < centering + targetW + containerOffset ? headerJs.winW - targetW : centering;

            //set the position
            $(target).css('left', centering);
        }
        mod.targetWi = function(target){
            var theW = 0;
            
            $(target).children().each(function(){
                var a = $(this).innerWidth();
                theW = a > theW ? a : theW;
            });
            return theW;
        }

        mod.positionEach = function(){
            if(mod.theSlide.length >= 1 && headerJs.winW > headerJs.breakpoint){
                mod.theSlide.each(function(){
                    mod.slideAdjust(this);
                });
            }
        }
        //run 
        mod.init();
        $(window).resize(mod.init);
    }
    //do any adjust to prevent errors on load
    headerJs.loadAdjust = function(){
        var mod = this;
        mod.menu = $('.l_header_menu');
        mod.init = function(){
            mod.unflicker();
        }
        //remove flicker
        mod.unflicker = function(){
            mod.menu.css({opacity:1});
        }
        mod.init();
    }
    
    //set current viewmode
    // headerJs.viewmodeSet = function(){
    //     var mod = this;
    //     mod.init = function(){
    //         mod.event();
    //         mod.setmode();
    //         $(window).on('resize',function(){mod.setmode()});
    //     }
    //     mod.event = function(state){
    //         var evt = $.Event('headerViewmodeChange');
    //         evt.state = state;
            
    //     }
    //     mod.setmode = function(){
    //         var current = headerJs.isPc() ? 'PC':'SP';
    //         if (headerJs.viewmode=== undefined){
    //             headerJs.viewmode = current;
    //         }else if(headerJs.viewmode !== current){
    //             $(window).trigger('headerViewmodeChange');
    //             headerJs.viewmode = current;
    //         }
            
    //     }
    //     //run mod
    //     mod.init();
    // }

    // headerJs.bodyDisplace = function(){
    //     var mod = this;
    //     mod.init = function(){
    //         mod.setPad();
    //     }
    //     mod.setPad = function(){
    //         $('.l_main').css({paddingTop: headerJs.upperH});
    //     }
    //     mod.init();
    //     $(window).on('resize',mod.init);
    // }
    headerJs.mobileMenu = function(){
        var mod = this;
        mod.speed = 300;
        mod.init = function(){
            mod.setter();
            $(window).resize(function(){mod.setter()});
            $('.l_header_burger').click(function(e){
                e.stopPropagation();
                mod.menuRun();
            });
            
        }
        
        mod.setter= function(){
            if(!headerJs.isPc() && !$('.l_header_menu').hasClass('is_open')){
                $('.l_header_menu').hide(); 
            }else{
                $('.l_header_menu').show(); 
            }
        }
        mod.headerMenuOpen = function(){
            $('.l_header_menu').stop(true).slideDown(250);
        }
        mod.headerMenuClose = function(){
            $('.l_header_menu').stop(true).slideUp(200);
        }
        mod.menuRun = function(){
            
            var classTargets = '.l_header_menu,.l_header_upper, .l_header_burger, .l_header_overlay';
            if($('.l_header_burger').hasClass('is_open')){
                $(classTargets).removeClass('is_open');
                mod.headerMenuClose();
                // if (!headerJs.isPc()){
                //     $('.l_header').velocity({height:headerJs.upperH},mod.speed);
                // }
            }else{
                $(classTargets).addClass('is_open');
                mod.headerMenuOpen();
                // if (!headerJs.isPc()){
                //     $('.l_header').velocity({height:headerJs.upperH+headerJs.menuH},mod.speed);
                // }
            }
        }
        mod.init()
    }
    //set accordion
    headerJs.accordion =function(){
        var mod =this;
        mod.init = function(){
            //$('.js_accordion').hide(); //hide all accordions;
            mod.setup();
        }
        //slide position
        mod.position = function($target,isOpen){
            if(isOpen && headerJs.isPc()){
                //$target.css()
                $target.css({
                    //top:$target.data('top')
                })
            }else if(headerJs.isPc()){
                $target.css({
                    //top:''
                })
            }
            else{
                $target.css({
                    //top:''
                })
            }
        }
        //slide 
        //slide toggle instructions
        mod.open = function($target){
            var $jsAccordion = $target.next(".js_accordion");
            //mod.position($jsAccordion,true);
            $target.addClass('is_open').next(".js_accordion")
            //.slideDown(250)
            .addClass('is_open');
            if(!headerJs.isPc()){
                $jsAccordion.slideDown(mod.speed);
            }
        }
        mod.close = function($target){
            var $jsAccordion = $target.next(".js_accordion");
            $target.removeClass('is_open').next(".js_accordion")
            //.slideUp(250, mod.position($jsAccordion, false))
            .removeClass('is_open');
            if(!headerJs.isPc()){
                $jsAccordion.slideUp(mod.speed);
            }
        }
        //setup accordion
        mod.setup = function(){
            //on PC
            $(".js_accordionTrigger").on('mouseover',function(e){
                if(headerJs.isPc()){
                    $(this).next(".js_accordion").stop(true,true);
                    if (!$(this).hasClass('is_open')){
                        mod.open($(this));
                    }
                }  
            });
            $('.js_accordionTrigger, .js_accordion').on('mouseout',function(e){
                if(headerJs.isPc()){
                    var thisAccordion;
                    var isHovering = 0;

                    //populate this accordion
                    var second = $(this).hasClass('js_accordionTrigger') ? $(this).next() : $(this).prev();
                    thisAccordion = [$(this),second];

                    //console.log($(e.relatedTarget).parents()[1]);
                    //match the targets
                    for (var i = 0;i < thisAccordion.length; i++){
                        if(thisAccordion[i][0]=== e.relatedTarget){
                            isHovering++;
                        }else if(thisAccordion[i][0]=== $(e.relatedTarget).parent()[0]){
                            //cursor hovers over child
                            isHovering++;
                        }else if(thisAccordion[i][0]=== $(e.relatedTarget).parents()[1]){
                            //this is if cursor hovers over grandchilds
                            isHovering++;
                        }
                    }
                    //close if no match
                    if(isHovering === 0){
                        var closeDiv = $(this).hasClass('js_accordionTrigger') ? $(this) : $(this).prev();
                        mod.close(closeDiv);
                    }
                }

            });

            //on SP
            $(".js_accordionTrigger").click(function(e) {
                if(!headerJs.isPc()){
                    e.preventDefault();
                    // $(this).next(".js_accordion").stop(true,true);
                    // if($(this).hasClass('is_open')){
                    //     mod.close($(this));
                    // }else{
                    //     mod.open($(this));
                    // }
                }
            });
        }
        mod.init();
    }
    //run stuff on Viewmode Change
    // headerJs.onViewmodeChange = function(){
    //     var mod = this;
    //     mod.init = function(){
    //         $(window).one('headerViewmodeChange',function(e){
    //             mod.exec(e);
    //         });
    //     }
    //     //execute
    //     mod.exec = function(e){
    //         //console.log('cambio');
    //     }
    //     //run mod
    //     mod.init();
    // }

    //RUN
    headerJs.init();

});