$(function () {
    var getQuery = function(){
        var result = {};
        var str = location.search.substring(1);
        if(!str){ return false; }
        var params = str.split('&');
        for(var i = 0;i<params.length;i++){
          var kv = params[i].split('=');
          result[kv[0]] = decodeURIComponent(kv[1]);
        }
        return result;
    };
    var query = getQuery();

    var ua = navigator.userAgent;
    var isMobile = (ua.indexOf('iPhone') > 0 || ua.indexOf('iPod') > 0 || ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0 || ua.indexOf('iPad') > 0 || ua.indexOf('Android') > 0)?true:false;

    //START APP ::::::::::::::::::::::::::::::::::::::::::::
    var app = this;
    app.breakpoint = 768;
    app.winW;
    app.winH;
    app.bLazy;
    
    //INIT-----------------------------------------------
    app.init = function(){
        //app.mvTopHeight();
        app.otherInit();
        app.sizes();
        app.myEvents();
        app.lazyLoading();
        app.sliders();
        app.toTop();
        app.modal();
        app.news();
        // app.Resizer();

    }
    //HELPER FUNCTIONS---------------------------------
    // HELPER check if is PC
    app.isPc = function(){
        if(app.winW > app.breakpoint){
            return true;
        }else{
            return false;
        }
    }
    // HELPER check if is odd or even number
    app.isOdd = function(num){
        var result =  num % 2;
        result = result===1? true : false;
        return result;
    }
    // HELPER toggle class, only once per selection
    app.toggleClass = function(theThis,targetClass, toggleClass){
        var mod = this;
        mod.targetC;
        mod.init = function(){
            mod.defineTargetC();
            mod.remove();
            mod.add();
        }
        mod.defineTargetC = function(){
            var typeOf = typeof targetClass;
            if (typeOf === 'string'){
                mod.targetC = $('.'+targetClass);
            }else{
                mod.targetC = targetClass;
            }
        }
        mod.remove = function(){
            mod.targetC.each(function(){
                //toggleClass cannot have a '.' in front
                if($(this).hasClass(toggleClass)){
                    $(this).removeClass(toggleClass);
                }
            })
        }
        mod.add = function(){
            theThis.addClass(toggleClass);
        }
        mod.init();
    } 
    //MAIN FUNCIONS------------------------------------
     //initialize outside scripts and plugins;
     app.otherInit = function(){
         var mod = this;
         mod.init = function(){
            mod.initObjectFitImages();
            //mod.breakpoint();
         }
         mod.initObjectFitImages = function(){
            objectFitImages();
         }
         //setup custom breakpoint event
        //  mod.breakpoint = function(){
        //      $(window).setBreakpoints({breakpoints:[768]});
        //  }
         mod.init();
     }
     //set all sizes
     app.sizes = function(){
        var mod = this;
        mod.init = function(){         
            mod.getW();
            mod.getH();
            //mod.bizlinks();
        }
        //get widths
        mod.getW = function(){
            app.winW = $(window).width();
        }
        //get heights
        mod.getH = function(){
            app.winH = $(window).height();
        }
        // //set business link height
        //run mod
        mod.init();
        $(window).on('resize',mod.init);
    }
    //custom events
    app.myEvents = function(){
        var mod = this;
        mod.initialtViewport; //is current viewport PC?
        mod.changeBreakpoint;
        mod.init = function(){
            mod.eventSetup();
            $(window).resize(mod.resize);
        }
        mod.eventSetup= function(){
            mod.initialtViewport = app.isPc();
            mod.changeBreakpoint = document.createEvent('Event');
            mod.changeBreakpoint.initEvent('changeBreakpoint',true,true);
            document.addEventListener('changeBreakpoint',function(e){},false);
        }
        mod.breakpointChange = function(){
            if(mod.initialtViewport !== app.isPc()){
                mod.initialtViewport = app.isPc();
                document.dispatchEvent(mod.changeBreakpoint);
            }
        }
        mod.resize = function(){
            mod.breakpointChange();
        }
        mod.init();
    }
    //Set the sliders
    app.sliders = function(){
        var mod =this;
        mod.init =function(){
            mod.setSlider();
        }
        mod.setSlider = function(){
            //top page slider
            if($('.top_mv_slider').length !== 0){
                //set lazyload
                var topSlidebLazy = new Blazy({
                    selector:'.b-lazySlide',
                    loadInvisible: true
                    // src:'assets/img/top/mv/slidepc.png',
                    // breakpoints:[{
                    //     width:767,
                    //     src:'assets/img/top/mv/slidesp.png',
    
                    // }]
                });
                //timeout fix load error
                setTimeout(function(){
                    topSlidebLazy.revalidate();
                },1500);
                //set slider
                $('.top_mv_slider').slick({
                    arrows: false,
                    dots: true,
                    autoplay:true
                });
                //refresh lazyload
                $('.top_mv_slider').on('afterChange',function(e,slick,c){
                    topSlidebLazy.revalidate();
                })
                
            }
            //business page slider
            if($('.business_slider').length !== 0){
                //set lazyload
                var topSlidebLazy = new Blazy({
                    selector:'.b-lazySlide',
                    loadInvisible: true
                });
                //timeout fix load error
                setTimeout(function(){
                    topSlidebLazy.revalidate();
                },1500);
                ///set slider
                $('.business_slider').each(function(){
                    $(this).slick({
                        arrows: false,
                        dots: true,
                        autoplay:true
                    });
                });
                //refresh lazyload
                $('.business_slider').on('beforeChange afterChange',function(e,slick,c){
                    topSlidebLazy.revalidate();
                })
            }
        }
        // mod.slideResize = function(){
        //     if($('.business_slider').length !== 0){
        //         console.log('slide resize');
        //         $('.business_slider').each(function(){
        //             $(this).slick('resize');
        //         });
        //     }
        // }
        mod.init();
    }
    //THE FUNCTIONS

    //scroll to top
    app.toTop = function(){
        var mod = this;
        mod.btn = $('.l_footer_btn');
        mod.init = function(){
            mod.btn.click(function(e){mod.scroller(e)});
        }
        mod.scroller = function(e){
            e.preventDefault();
            $('html,body').velocity('stop').velocity('scroll',{duration:300});
        }

        mod.init();
    }
    app.lazyLoading = function(){
        var mod = this;
        mod.init = function(){
            mod.blazy();
        }
        mod.blazy= function(){
            var bLazy = new Blazy({

            });
            var bLazyTitles = new Blazy({
                selector:'.b-lazyTitles',
                loadInvisible: true
            });
            //revalidate lazyload on change of screen size
            $(document).on('changeBreakpoint',function(){
                bLazy.revalidate();
                bLazyTitles.revalidate();
            });
            //timeout fix load error
            setTimeout(function(){
                bLazy.revalidate();
                bLazyTitles.revalidate();
            },1500);
            
        }
        mod.init();
    }
    app.modal = function(){
        var mod = this;
        mod.init=function(){
            mod.modal();
        }
        
        mod.modal = function(){
            $('.c_modalImg').click(function(e){
                var imgSrc= $(this).attr('src');//this.currentSrc;
                $('#c_modalWrap').addClass('is_active');
                $('.c_modalImg_content').append('<img class="modalImgInsert" src="'+imgSrc+'"alt="IMG">')

            })
            $('.c_modalImg_close,.c_modalImg_bg').click(function(e){
                e.preventDefault();
                $('#c_modalWrap').removeClass('is_active');
                $('.c_modalImg_content').children('img').remove();
            });
        }
        mod.init();
    }
    app.news = function(){
        var mod = this;
        mod.init = function(){
            mod.accordion();
        }
        mod.accordion =function(){
            if($('.news_article').length > 0){
                $('.news_article_title').click(function(e){
                    var $this = $(this);
                    var $details = $this.parent().siblings('.news_article_details');

                    //check if has .news_article_details
                    if($details.length>0){
                        e.preventDefault();
                        switch($details.hasClass('is_active')){
                            case true:
                                $details.removeClass('is_active');
                                break;
                            case false:
                                $details.addClass('is_active');
                        }

                    }
                    console.log($details);
                });
            }
        }

        mod.init();
    }



    //RUN------------------------------
    app.init();
});